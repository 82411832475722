import gql from 'graphql-tag';

export default gql`
  mutation upsertItemsFulfillmentSpecs($input: UpsertItemsFulfillmentSpecsInput!) {
    upsertItemsFulfillmentSpecs(input: $input) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
