import gql from 'graphql-tag';

export default gql`
  query stores($filter: StoreFilterInput!, $pagination: Pagination!) {
    stores(filter: $filter, pagination: $pagination) {
      total
      totalPage
      data {
        id
        name
        imageUrl
        telephone
        disabled
        status
        recordId
        location {
          city
          district
          address
          ward
          noteForAddress
        }
        buyerId
      }
    }
  }
`;
