/*
 * CatalogPage Messages
 *
 * This contains all the text for the CatalogPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CatalogPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Danh sách sản phẩm',
  },
  selectCategory: {
    id: `${scope}.selectCategory`,
    defaultMessage: 'Chọn danh mục',
  },
  selectSubCategory: {
    id: `${scope}.selectSubCategory`,
    defaultMessage: 'Chọn danh mục con',
  },
  searchProduct: {
    id: `${scope}.searchProduct`,
    defaultMessage: 'Nhập tên hoặc mã sản phẩm',
  },
  addItem: {
    id: `${scope}.addItem`,
    defaultMessage: 'Thêm sản phẩm',
  },
  stock: {
    id: `${scope}.stock`,
    defaultMessage: 'Tình trạng',
  },
  photo: {
    id: `${scope}.photo`,
    defaultMessage: 'Ảnh',
  },
  selectStock: {
    id: `${scope}.selectStock`,
    defaultMessage: 'Chọn tình trạng',
  },
  selectPhoto: {
    id: `${scope}.selectPhoto`,
    defaultMessage: 'Chọn Ảnh',
  },
  ENABLED: {
    id: `${scope}.ENABLED`,
    defaultMessage: 'Còn hàng',
  },
  DISABLED: {
    id: `${scope}.DISABLED`,
    defaultMessage: 'Hết hàng',
  },
  HAS_PHOTO: {
    id: `${scope}.HAS_PHOTO`,
    defaultMessage: 'Có ảnh',
  },
  NO_PHOTO: {
    id: `${scope}.NO_PHOTO`,
    defaultMessage: 'Không có ảnh',
  },
  selectExclusive: {
    id: `${scope}.selectExclusive`,
    defaultMessage: 'Độc quyền',
  },
  ALL: {
    id: `${scope}.ALL`,
    defaultMessage: 'Tất cả',
  },
  printQRCode: {
    id: `${scope}.printQRCode`,
    defaultMessage: 'In mã QR',
  },
  selectProductStatus: {
    id: `${scope}.selectProductStatus`,
    defaultMessage: 'Chọn trạng thái đơn hàng',
  },
  selectProductMessage: {
    id: `${scope}.selectProductMessage`,
    defaultMessage: 'Bạn đã chọn {selectedProductCount} sản phẩm.',
  },
  unSelectProducts: {
    id: `${scope}.unSelectProducts`,
    defaultMessage: 'Bỏ chọn tất cả',
  },
  acceptSelectedProduct: {
    id: `${scope}.acceptSelectedProduct`,
    defaultMessage: 'Chấp nhận đơn hàng',
  },
  printSelectedProduct: {
    id: `${scope}.printSelectedProduct`,
    defaultMessage: 'In đơn hàng',
  },
});
