import gql from 'graphql-tag';

export default gql`
  query getItemsFulfillmentSpecs($input: GetItemFulfillmentSpecsInput) {
    getItemsFulfillmentSpecs(input: $input) {
      data {
        itemId
        uuid
        width
        height
        depth
        weight
        passed {
          images
          description
        }
        notPassed {
          images
          description
        }
      }
    }
  }
`;
