/*
 * OrdersPage Messages
 *
 * This contains all the text for the OrdersPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.OrdersPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Đơn hàng',
  },
  selectOrderStatus: {
    id: `${scope}.selectOrderStatus`,
    defaultMessage: 'Chọn trạng thái đơn hàng',
  },
  selectOrderMessage: {
    id: `${scope}.selectOrderMessage`,
    defaultMessage: 'Bạn đã chọn {selectedOrderCount} đơn hàng.',
  },
  unSelectOrders: {
    id: `${scope}.unSelectOrders`,
    defaultMessage: 'Bỏ chọn tất cả',
  },
  acceptSelectedOrder: {
    id: `${scope}.acceptSelectedOrder`,
    defaultMessage: 'Chấp nhận đơn hàng',
  },
  printSelectedOrder: {
    id: `${scope}.printSelectedOrder`,
    defaultMessage: 'In đơn hàng',
  },
  importOrder: {
    id: `${scope}.importOrder`,
    defaultMessage: 'Nhập đơn hàng',
  },
  importOrderFail: {
    id: `${scope}.importOrderFail`,
    defaultMessage: 'Đơn hàng thất bại',
  },
  importOrderSuccess: {
    id: `${scope}.importOrderSuccess`,
    defaultMessage: 'Đơn hàng thành công',
  },
  printAllOrders: {
    id: `${scope}.printAllOrders`,
    defaultMessage: 'In tất cả đơn hàng',
  },
  storeStatus: {
    id: `${scope}.storeStatus`,
    defaultMessage: 'Trạng thái cửa hàng',
  },
  selectStoreStatus: {
    id: `${scope}.selectStoreStatus`,
    defaultMessage: 'Chọn trạng thái cửa hàng',
  },
  andMore: {
    id: `${scope}.andMore`,
    defaultMessage: 'và còn nữa',
  },
});
