import gql from 'graphql-tag';

export default gql`
  mutation upsertItemPriceForBuyers($input: UpsertItemPriceForBuyersInput!) {
    upsertItemPriceForBuyers(input: $input) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
