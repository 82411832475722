export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO-8601 encoded UTC date string. */
  DateTime: any;
  /** An ISO-8601 encoded date string. */
  Date: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AccountCreateInput = {
  companyName: Scalars['String'];
  telephone: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  lang: Language;
};

export type AccountUpdateInput = {
  name: Scalars['String'];
  telephone: Scalars['String'];
  imageUrl: Scalars['String'];
  lang?: Maybe<Language>;
};

export type ActionHistoryInfo = {
  __typename?: 'ActionHistoryInfo';
  id?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['String']>;
  objectType?: Maybe<Scalars['String']>;
  actioner?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  actionType?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['Float']>;
  dateTime?: Maybe<Scalars['String']>;
  oldData?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['String']>;
};

export type ApiResponse = MutationResponse & {
  __typename?: 'APIResponse';
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
};

export type BooleanValueByRegion = {
  value?: Maybe<Scalars['Boolean']>;
};

export type Buyer = {
  __typename?: 'Buyer';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  imageUrl?: Maybe<Scalars['String']>;
  redInvoiceInfo?: Maybe<RedInvoiceInfo>;
  lastOrder?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  stores?: Maybe<Array<Maybe<Store>>>;
  region?: Maybe<Scalars['String']>;
  isMergedOrders?: Maybe<Scalars['Boolean']>;
  isHideDeliveryNotePayment?: Maybe<Scalars['Boolean']>;
};

export type BuyerContact = {
  __typename?: 'BuyerContact';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  telephone?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  lang?: Maybe<Language>;
};

export type BuyerContactInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  telephone: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  lang: Language;
};

export type BuyerFilterInput = {
  query?: Maybe<Scalars['String']>;
  paidStatus?: Maybe<PaidStatusBuyer>;
  onlyOrdered?: Maybe<Scalars['Boolean']>;
  onlyAdded?: Maybe<Scalars['Boolean']>;
  regionCode?: Maybe<Scalars['String']>;
};

export type BuyerInput = {
  name: Scalars['String'];
  telephone?: Maybe<Scalars['String']>;
  location?: Maybe<LocationInput>;
  paymentMethod?: Maybe<PaymentMethod>;
  companyId?: Maybe<Scalars['Int']>;
  imageUrl?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  isMergedOrders?: Maybe<Scalars['Boolean']>;
  isHideDeliveryNotePayment?: Maybe<Scalars['Boolean']>;
  wardId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
};

export type BuyerSettings = {
  __typename?: 'BuyerSettings';
  id: Scalars['Int'];
  buyerId: Scalars['Int'];
  moq: Scalars['Int'];
  deliverySchedule: Array<DeliveryTimeSlotSetting>;
  fulfillmentTime: FulfillmentTime;
  peopleInCharge: Array<User>;
};

export type BuyerSettingsInput = {
  moq?: Maybe<Scalars['Int']>;
  deliverySchedule?: Maybe<Array<DeliveryTimeSlotSettingInput>>;
  fulfillmentTime?: Maybe<FulfillmentTimeInput>;
  peopleInChargeIdsList?: Maybe<Array<Scalars['ID']>>;
};

export type BuyerSettingsResponse = MutationResponse & {
  __typename?: 'BuyerSettingsResponse';
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
  settings?: Maybe<BuyerSettings>;
};

export type BuyerSort = {
  field?: Maybe<BuyerSortField>;
  direction?: Maybe<SortDirection>;
};

export enum BuyerSortField {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export type BuyersResponse = {
  __typename?: 'BuyersResponse';
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
  data?: Maybe<Array<Maybe<Buyer>>>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export type Category = {
  __typename?: 'Category';
  nameLocal?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parent?: Maybe<Category>;
  id?: Maybe<Scalars['String']>;
};

export type ClientAddAllSellingListInput = {
  clientId: Scalars['String'];
  clientType: ClientType;
};

export type ClientAddSellingListInput = {
  clientId: Scalars['String'];
  clientType: ClientType;
  sellingList: Array<SellingItem>;
};

export type ClientDeleteInput = {
  clientId: Scalars['String'];
  clientType: ClientType;
};

export type ClientDeleteSellingItemInput = {
  clientId: Scalars['String'];
  clientType: ClientType;
  itemIdsList: Array<Scalars['String']>;
};

export type ClientRemoveAllSellingListInput = {
  clientId: Scalars['String'];
  clientType: ClientType;
};

export type ClientSellingFilter = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<SellingType>;
};

export type ClientSellingListInput = {
  clientId: Scalars['String'];
  clientType: ClientType;
  pagination?: Maybe<Pagination>;
  filter?: Maybe<ClientSellingFilter>;
};

export enum ClientType {
  Invitation = 'INVITATION',
  Buyer = 'BUYER',
}

export type ClientUpdateInput = {
  clientId: Scalars['String'];
  clientType: ClientType;
  buyer?: Maybe<BuyerInput>;
  redInvoiceInfo?: Maybe<CompanyUpdateRedInvoiceInput>;
  storesList?: Maybe<Array<Maybe<CreateStoreInput>>>;
  settings?: Maybe<BuyerSettingsInput>;
};

export enum CompanyStatus {
  Disabled = 'DISABLED',
  Deleted = 'DELETED',
  Public = 'PUBLIC',
  Private = 'PRIVATE',
  NotApproved = 'NOT_APPROVED',
}

export type CompanyUpdateInput = {
  name: Scalars['String'];
  imageUrl: Scalars['String'];
  telephone?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionVn?: Maybe<Scalars['String']>;
};

export type CompanyUpdateRedInvoiceInput = {
  mst: Scalars['String'];
  location: LocationInput;
  companyName: Scalars['String'];
  emailToReceiveEInvoice?: Maybe<Scalars['String']>;
};

export type CreateStoreInput = {
  name: Scalars['String'];
  telephone: Scalars['String'];
  location: LocationInput;
  imageUrl?: Maybe<Scalars['String']>;
};

export type Csv = {
  __typename?: 'CSV';
  content: Scalars['String'];
};

export type DatetimeRange = {
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
};

export type DeliveryDatetime = {
  __typename?: 'DeliveryDatetime';
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type DeliveryDatetimeInput = {
  start: Scalars['String'];
  end: Scalars['String'];
};

export type DeliveryDayOff = {
  __typename?: 'DeliveryDayOff';
  name: Scalars['String'];
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type DeliveryDayOffInput = {
  name: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
};

export type DeliveryFee = {
  __typename?: 'DeliveryFee';
  fee?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Float']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type DeliveryNote = {
  __typename?: 'DeliveryNote';
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type DeliveryTime = {
  __typename?: 'DeliveryTime';
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type DeliveryTimeSlot = {
  __typename?: 'DeliveryTimeSlot';
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['String']>;
};

export type DeliveryTimeSlotSetting = {
  __typename?: 'DeliveryTimeSlotSetting';
  weekday?: Maybe<Scalars['String']>;
  start: Scalars['String'];
  end: Scalars['String'];
  timeWindow?: Maybe<Scalars['Int']>;
};

export type DeliveryTimeSlotSettingInput = {
  weekday?: Maybe<Scalars['String']>;
  start: Scalars['String'];
  end: Scalars['String'];
  timeWindow?: Maybe<Scalars['Int']>;
};

export type DownloadSellingPdfInput = {
  clientId: Scalars['String'];
  clientType: ClientType;
  peopleInChargeList: Array<Maybe<Scalars['String']>>;
  lang: Language;
};

export type DownloadSellingPdfResponse = MutationResponse & {
  __typename?: 'DownloadSellingPDFResponse';
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
  url: Scalars['String'];
};

export type FulfillmentTime = {
  __typename?: 'FulfillmentTime';
  hoursToDelivery?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  cutoffTime?: Maybe<Scalars['String']>;
};

export type FulfillmentTimeInput = {
  hoursToDelivery?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  cutoffTime?: Maybe<Scalars['String']>;
};

export type GetAllKamereoRegionsResponse = {
  __typename?: 'GetAllKamereoRegionsResponse';
  data?: Maybe<Array<Maybe<RegionItem>>>;
};

export type GetInvitationsInput = {
  statusesList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GetItemFulfillmentSpecsInput = {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uuids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GetItemsFulfillmentSpecsResponse = {
  __typename?: 'GetItemsFulfillmentSpecsResponse';
  data?: Maybe<Array<Maybe<ItemsFulfillSpec>>>;
};

export type ImportOrderInput = {
  itemsList: Array<ImportOrderItem>;
  storeId: Scalars['String'];
  deliveryNotesList?: Maybe<Array<Scalars['String']>>;
  deliveryDatetime: DeliveryDatetimeInput;
  buyerId: Scalars['Int'];
  orderType?: Maybe<OrderType>;
  zeroOrderInfo?: Maybe<ZeroOrderInput>;
};

export type ImportOrderItem = {
  internalCode: Scalars['String'];
  quantity: Scalars['String'];
  itemNote?: Maybe<Scalars['String']>;
};

export type ImportOrderMutationResponse = MutationResponse & {
  __typename?: 'ImportOrderMutationResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  /** @deprecated Use order object */
  orderId?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type IntValueByRegion = {
  value?: Maybe<Scalars['Int']>;
};

export type Invitation = {
  __typename?: 'Invitation';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  imageUrl?: Maybe<Scalars['String']>;
  status?: Maybe<InvitationStatus>;
  redInvoiceInfo?: Maybe<RedInvoiceInfo>;
  lastOrder?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  stores?: Maybe<Array<Maybe<Store>>>;
  settings?: Maybe<InvitationSettings>;
};

export type InvitationCreateResponse = {
  __typename?: 'InvitationCreateResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
};

export type InvitationInput = {
  invitationId: Scalars['ID'];
};

export type InvitationResponse = MutationResponse & {
  __typename?: 'InvitationResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  invitation?: Maybe<Invitation>;
};

export type InvitationSettings = {
  __typename?: 'InvitationSettings';
  moq: Scalars['Int'];
  deliverySchedule: Array<DeliveryTimeSlotSetting>;
  fulfillmentTime: FulfillmentTime;
  peopleInCharge: Array<User>;
};

export type InvitationsListResponse = {
  __typename?: 'InvitationsListResponse';
  data?: Maybe<Array<Maybe<Invitation>>>;
};

export type InvitationSlotResponse = {
  __typename?: 'InvitationSlotResponse';
  slot: Scalars['Int'];
};

export enum InvitationStatus {
  Pending = 'PENDING',
  Canceled = 'CANCELED',
  AcceptedAsNewBuyer = 'ACCEPTED_AS_NEW_BUYER',
  AcceptedAsExistingBuyer = 'ACCEPTED_AS_EXISTING_BUYER',
}

export type ItemsFulfillSpec = {
  __typename?: 'ItemsFulfillSpec';
  itemId?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  passed?: Maybe<QualityItem>;
  notPassed?: Maybe<QualityItem>;
};

export type ItemsFulfillSpecInput = {
  itemId: Scalars['Int'];
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  passed?: Maybe<QualityItemInput>;
  notPassed?: Maybe<QualityItemInput>;
};

export enum Language {
  En = 'en',
  Vi = 'vi',
}

export type LimitedTimeOffer = {
  __typename?: 'LimitedTimeOffer';
  price: UnitPrice;
  period: LimitedTimeOfferPeriod;
};

export type LimitedTimeOfferByRegionInput = {
  period: LimitedTimeOfferPeriodInput;
  value: Scalars['Int'];
  vat: Scalars['Int'];
  vatIncluded: Scalars['Boolean'];
};

export type LimitedTimeOfferInput = {
  price: LimitedTimeOfferPriceInput;
  period: LimitedTimeOfferPeriodInput;
};

export type LimitedTimeOfferPeriod = {
  __typename?: 'LimitedTimeOfferPeriod';
  start: Scalars['String'];
  end: Scalars['String'];
};

export type LimitedTimeOfferPeriodInput = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type LimitedTimeOfferPriceInput = {
  value: Scalars['Int'];
  vat: Scalars['Int'];
  vatIncluded: Scalars['Boolean'];
};

export type Location = {
  __typename?: 'Location';
  city: Scalars['String'];
  district: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  ward?: Maybe<Scalars['String']>;
  noteForAddress?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  city: Scalars['String'];
  district: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  ward?: Maybe<Scalars['String']>;
  noteForAddress?: Maybe<Scalars['String']>;
};

export type LoginResponse = MutationResponse & {
  __typename?: 'LoginResponse';
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
  token?: Maybe<Scalars['String']>;
  rememberMeToken?: Maybe<Scalars['String']>;
};

export type Media = {
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
};

export type MediaType = {
  __typename?: 'MediaType';
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
};

export type MeUpdatePasswordInput = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  orderUpdateFulfilledItem?: Maybe<OrderMutationResponse>;
  orderAccept?: Maybe<OrderMutationResponse>;
  orderDecline?: Maybe<OrderMutationResponse>;
  orderImport?: Maybe<ImportOrderMutationResponse>;
  orderUpdateDeliveryDateTime: OrderUpdateDeliveryDateTimeResponse;
  productCreate?: Maybe<ProductMutationResponse>;
  productUpdate?: Maybe<ProductMutationResponse>;
  productSetExclusive?: Maybe<ProductMutationResponse>;
  productSetStock?: Maybe<ProductMutationResponse>;
  productDelete?: Maybe<ApiResponse>;
  productSetDiscountPrice?: Maybe<ApiResponse>;
  upsertItemPriceForBuyers?: Maybe<ApiResponse>;
  productDeleteDiscountPrice?: Maybe<ApiResponse>;
  signImageUpload?: Maybe<SignUploadResponse>;
  signVideoUpload?: Maybe<SignUploadVideoResponse>;
  meLogin?: Maybe<LoginResponse>;
  meRelogin?: Maybe<LoginResponse>;
  meUpdateLang?: Maybe<ApiResponse>;
  meUpdatePassword?: Maybe<ApiResponse>;
  meForgetPassword?: Maybe<ApiResponse>;
  meResetPassword?: Maybe<ApiResponse>;
  accountCreate?: Maybe<ApiResponse>;
  accountUpdate?: Maybe<ApiResponse>;
  userInvite?: Maybe<ApiResponse>;
  userRemove?: Maybe<ApiResponse>;
  companyUpdate?: Maybe<ApiResponse>;
  companyUpdateRedInvoice?: Maybe<ApiResponse>;
  checkEmail?: Maybe<ApiResponse>;
  setSettings?: Maybe<SettingsResponse>;
  setBuyerSettings?: Maybe<BuyerSettingsResponse>;
  setPaymentMethod?: Maybe<ApiResponse>;
  clientAddSellingList?: Maybe<ApiResponse>;
  clientDeleteSellingItem?: Maybe<ApiResponse>;
  clientUpdate?: Maybe<InvitationResponse>;
  clientDelete?: Maybe<ApiResponse>;
  clientAddAllSellingList?: Maybe<ApiResponse>;
  clientRemoveAllSellingList?: Maybe<ApiResponse>;
  clientDownloadSellingPDF?: Maybe<DownloadSellingPdfResponse>;
  invitationCreate?: Maybe<InvitationCreateResponse>;
  invitationDelete?: Maybe<ApiResponse>;
  upsertItemsFulfillmentSpecs?: Maybe<ApiResponse>;
};

export type MutationOrderUpdateFulfilledItemArgs = {
  input: OrderUpdateFulfilledItemInput;
};

export type MutationOrderAcceptArgs = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type MutationOrderDeclineArgs = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type MutationOrderImportArgs = {
  input: ImportOrderInput;
};

export type MutationOrderUpdateDeliveryDateTimeArgs = {
  input: OrderUpdateDeliveryDateTimeInput;
};

export type MutationProductCreateArgs = {
  input: ProductInput;
};

export type MutationProductUpdateArgs = {
  input: ProductInput;
};

export type MutationProductSetExclusiveArgs = {
  productId: Scalars['ID'];
  exclusive: Scalars['Boolean'];
};

export type MutationProductSetStockArgs = {
  productId: Scalars['ID'];
  disabled: Scalars['Boolean'];
  productInfoByRegion: Array<Maybe<ProductInfoByRegionInput>>;
};

export type MutationProductDeleteArgs = {
  productId: Scalars['ID'];
};

export type MutationProductSetDiscountPriceArgs = {
  input: PricingInput;
};

export type MutationUpsertItemPriceForBuyersArgs = {
  input: UpsertItemPriceForBuyersInput;
};

export type MutationProductDeleteDiscountPriceArgs = {
  input: PricingInput;
};

export type MutationSignImageUploadArgs = {
  resourceGroup: ResourceGroup;
  contentType: Scalars['String'];
  fileName: Scalars['String'];
};

export type MutationSignVideoUploadArgs = {
  resourceGroup: ResourceGroup;
  contentType: Scalars['String'];
  fileName: Scalars['String'];
};

export type MutationMeLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: Maybe<Scalars['Boolean']>;
};

export type MutationMeReloginArgs = {
  token: Scalars['String'];
};

export type MutationMeUpdateLangArgs = {
  lang: Language;
};

export type MutationMeUpdatePasswordArgs = {
  input: MeUpdatePasswordInput;
};

export type MutationMeForgetPasswordArgs = {
  email: Scalars['String'];
};

export type MutationMeResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
  key: Scalars['String'];
};

export type MutationAccountCreateArgs = {
  input?: Maybe<AccountCreateInput>;
};

export type MutationAccountUpdateArgs = {
  input?: Maybe<AccountUpdateInput>;
};

export type MutationUserInviteArgs = {
  email?: Maybe<Scalars['String']>;
};

export type MutationUserRemoveArgs = {
  id: Scalars['ID'];
};

export type MutationCompanyUpdateArgs = {
  input?: Maybe<CompanyUpdateInput>;
};

export type MutationCompanyUpdateRedInvoiceArgs = {
  buyerId: Scalars['Int'];
  input: CompanyUpdateRedInvoiceInput;
};

export type MutationCheckEmailArgs = {
  email?: Maybe<Scalars['String']>;
};

export type MutationSetSettingsArgs = {
  input?: Maybe<SettingsInput>;
};

export type MutationSetBuyerSettingsArgs = {
  buyerId: Scalars['Int'];
  input: BuyerSettingsInput;
};

export type MutationSetPaymentMethodArgs = {
  buyerId?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type MutationClientAddSellingListArgs = {
  input?: Maybe<ClientAddSellingListInput>;
};

export type MutationClientDeleteSellingItemArgs = {
  input?: Maybe<ClientDeleteSellingItemInput>;
};

export type MutationClientUpdateArgs = {
  input?: Maybe<ClientUpdateInput>;
};

export type MutationClientDeleteArgs = {
  input?: Maybe<ClientDeleteInput>;
};

export type MutationClientAddAllSellingListArgs = {
  input?: Maybe<ClientAddAllSellingListInput>;
};

export type MutationClientRemoveAllSellingListArgs = {
  input?: Maybe<ClientRemoveAllSellingListInput>;
};

export type MutationClientDownloadSellingPdfArgs = {
  input?: Maybe<DownloadSellingPdfInput>;
};

export type MutationInvitationCreateArgs = {
  name?: Maybe<Scalars['String']>;
};

export type MutationInvitationDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationUpsertItemsFulfillmentSpecsArgs = {
  input?: Maybe<UpsertItemsFulfillmentSpecsInput>;
};

export type MutationResponse = {
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
};

export enum Notification {
  Email = 'EMAIL',
  Zalo = 'ZALO',
}

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  total: Scalars['String'];
  totalItemPrice?: Maybe<Scalars['String']>;
  items: Array<OrderItem>;
  orderDate: Scalars['String'];
  deliveryTime: DeliveryTime;
  acceptedAt?: Maybe<Scalars['String']>;
  acceptedBy?: Maybe<User>;
  acceptedNote?: Maybe<Scalars['String']>;
  declinedAt?: Maybe<Scalars['String']>;
  declinedBy?: Maybe<User>;
  declinedNote?: Maybe<Scalars['String']>;
  status: OrderStatus;
  paymentMethod: PaymentMethod;
  buyer: Buyer;
  store: Store;
  creator: BuyerContact;
  buyerNote?: Maybe<Scalars['String']>;
  /** @deprecated Use orderType instead */
  redelivery: Scalars['Boolean'];
  orderType?: Maybe<OrderType>;
  zeroOrderInfo?: Maybe<ZeroOrderInfo>;
  deliveryFee?: Maybe<DeliveryFee>;
};

export type OrderActivity = {
  __typename?: 'OrderActivity';
  time?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
};

export type OrderActivityResponse = MutationResponse & {
  __typename?: 'OrderActivityResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  orderActivity?: Maybe<Array<Maybe<OrderActivity>>>;
};

export type OrderCountSummary = {
  __typename?: 'OrderCountSummary';
  orderedWithinPeriod: Scalars['Int'];
  deliverWithinPeriod: Scalars['Int'];
  pending: Scalars['Int'];
};

export type OrderCreateResponse = MutationResponse & {
  __typename?: 'OrderCreateResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  orders: Array<Order>;
};

export type OrderFilterInput = {
  buyerId?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['String']>;
  storeStatus?: Maybe<StoreStatus>;
  statuses?: Maybe<Array<Maybe<OrderStatus>>>;
  orderDateRange?: Maybe<DatetimeRange>;
  deliveryDateRange?: Maybe<DatetimeRange>;
  orderIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type OrderHistoryDetailInput = {
  uuid: Scalars['String'];
  pagination?: Maybe<Pagination>;
};

export type OrderHistoryDetailResponse = {
  __typename?: 'OrderHistoryDetailResponse';
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
  dataList?: Maybe<Array<Maybe<ActionHistoryInfo>>>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  total: Scalars['String'];
  product: Product;
  orderedQuantity: Scalars['String'];
  deliveryQuantity: Scalars['String'];
  buyerNote: Scalars['String'];
  /** @deprecated This field should not be used anymore */
  quantity?: Maybe<Scalars['String']>;
  /** @deprecated Use orderedUnitPrice */
  orderedPrice?: Maybe<Scalars['String']>;
  /** @deprecated This field should not be used anymore */
  deliveredQuantity?: Maybe<Scalars['String']>;
  deliveredNote?: Maybe<Scalars['String']>;
  internalCode?: Maybe<Scalars['String']>;
  /** This unit price is a snapshot when an order is placed. */
  unitPriceAtOrderCreated: UnitPrice;
};

export type OrderMutationResponse = MutationResponse & {
  __typename?: 'OrderMutationResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  order?: Maybe<Order>;
};

export type OrderSort = {
  field?: Maybe<OrderSortField>;
  direction?: Maybe<SortDirection>;
};

export enum OrderSortField {
  CreatedAt = 'createdAt',
  DeliveryDate = 'deliveryDate',
  Status = 'status',
  Total = 'total',
}

export type OrdersResponse = {
  __typename?: 'OrdersResponse';
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
  data?: Maybe<Array<Maybe<Order>>>;
};

export type OrderStatistic = {
  __typename?: 'OrderStatistic';
  orderedWithinPeriod: Scalars['Int'];
  deliverWithinPeriod: Scalars['Int'];
  pending: Scalars['Int'];
};

export type OrderStatisticResponse = MutationResponse & {
  __typename?: 'OrderStatisticResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  data?: Maybe<OrderStatistic>;
};

export enum OrderStatus {
  All = 'ALL',
  PendingAcceptance = 'PENDING_ACCEPTANCE',
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  Sent = 'SENT',
  InProgress = 'IN_PROGRESS',
  PendingDelivery = 'PENDING_DELIVERY',
  Delivering = 'DELIVERING',
  Delivered = 'DELIVERED',
}

export type OrderTotalBucket = {
  __typename?: 'OrderTotalBucket';
  startTime?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export enum OrderType {
  PaidOrder = 'PAID_ORDER',
  Gift = 'GIFT',
  Sample = 'SAMPLE',
  Redelivery = 'REDELIVERY',
  Employee = 'EMPLOYEE',
}

export type OrderUpdateDeliveryDateTimeInput = {
  orderId: Scalars['ID'];
  deliveryTimeStart: Scalars['DateTime'];
  deliveryTimeEnd: Scalars['DateTime'];
};

export type OrderUpdateDeliveryDateTimeResponse = {
  __typename?: 'OrderUpdateDeliveryDateTimeResponse';
  success: Scalars['Boolean'];
  order?: Maybe<Order>;
};

export type OrderUpdateFulfilledItemInput = {
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  quantity: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
};

export type Origin = {
  __typename?: 'Origin';
  nameLocal?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Pagination = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};

export enum PaidStatus {
  Paid = 'PAID',
  Free = 'FREE',
}

export enum PaidStatusBuyer {
  Unknown = 'UNKNOWN',
  Invited = 'INVITED',
  Paid = 'PAID',
}

export enum PaymentMethod {
  Cod = 'COD',
  Debt_15Day = 'DEBT_15_DAY',
  Debt_1Month = 'DEBT_1_MONTH',
  BankTransfer = 'BANK_TRANSFER',
  Deposit = 'DEPOSIT',
  Debt_7Day = 'DEBT_7_DAY',
}

export type Permission = {
  __typename?: 'Permission';
  action: PermissionAction;
  scope?: Maybe<PermissionScope>;
};

export enum PermissionAction {
  SupplierDeliveryScheduleSet = 'SUPPLIER_DELIVERY_SCHEDULE_SET',
  SupplierEditCatalogStockStatus = 'SUPPLIER_EDIT_CATALOG_STOCK_STATUS',
  SupplierEditProductBasicInfo = 'SUPPLIER_EDIT_PRODUCT_BASIC_INFO',
  SupplierEditItemUnitPricePricing = 'SUPPLIER_EDIT_ITEM_UNIT_PRICE_PRICING',
  SupplierEditItemUnitPriceVat = 'SUPPLIER_EDIT_ITEM_UNIT_PRICE_VAT',
  SupplierEditItemDiscountPrice = 'SUPPLIER_EDIT_ITEM_DISCOUNT_PRICE',
  SupplierEditItemSpecialPrice = 'SUPPLIER_EDIT_ITEM_SPECIAL_PRICE',
  SupplierEditItemTags = 'SUPPLIER_EDIT_ITEM_TAGS',
  SupplierEditItemDescription = 'SUPPLIER_EDIT_ITEM_DESCRIPTION',
  SupplierEditClientSellingList = 'SUPPLIER_EDIT_CLIENT_SELLING_LIST',
  SupplierEditBuyerInfo = 'SUPPLIER_EDIT_BUYER_INFO',
  SupplierExportCsv = 'SUPPLIER_EXPORT_CSV',
  SupplierEditClientMoq = 'SUPPLIER_EDIT_CLIENT_MOQ',
  SupplierImportOrder = 'SUPPLIER_IMPORT_ORDER',
  SupplierOrderApproving = 'SUPPLIER_ORDER_APPROVING',
}

export type PermissionScope = {
  __typename?: 'PermissionScope';
  type: Scalars['String'];
  id: Scalars['String'];
};

export enum PhotoFilter {
  AllPhoto = 'ALL_PHOTO',
  NoPhoto = 'NO_PHOTO',
  HasPhoto = 'HAS_PHOTO',
}

export type Preservation = {
  __typename?: 'Preservation';
  nameLocal?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type PriceBuyer = {
  __typename?: 'PriceBuyer';
  buyer: Buyer;
  price?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Int']>;
  vatIncluded?: Maybe<Scalars['Boolean']>;
};

export type PricingInput = {
  productId: Scalars['ID'];
  buyerId: Scalars['Int'];
  price?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Int']>;
  vatIncluded?: Maybe<Scalars['Boolean']>;
};

export type PrintQrCodeItemsResponse = {
  __typename?: 'PrintQRCodeItemsResponse';
  pdfData?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  intId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameVn?: Maybe<Scalars['String']>;
  nameLocal?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionVn?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  detailsVn?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageFilePath?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  preservation?: Maybe<Scalars['String']>;
  /** @deprecated Use unitPrice */
  vat?: Maybe<Scalars['Int']>;
  uom?: Maybe<Scalars['String']>;
  uomValue?: Maybe<Scalars['String']>;
  uomLocal?: Maybe<Scalars['String']>;
  /** @deprecated Use unitPrice */
  price?: Maybe<Scalars['Int']>;
  /** @deprecated Use unitPrice */
  grossPrice?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<UnitPrice>;
  /** @deprecated Use unitPrice */
  specialPrice?: Maybe<Scalars['Int']>;
  specialUnitPrice?: Maybe<UnitPrice>;
  /** @deprecated Use productCategories instead */
  category?: Maybe<Scalars['String']>;
  /** @deprecated Use productCategories instead */
  categoryLocal?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  offMarket?: Maybe<Scalars['Boolean']>;
  internalCode?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  limitedTimeOffer?: Maybe<LimitedTimeOffer>;
  productCategories?: Maybe<Array<ProductCategory>>;
  brandId?: Maybe<Scalars['Int']>;
  brand?: Maybe<ProductBrand>;
  productInfoByRegion?: Maybe<Array<Maybe<ProductInfoByRegion>>>;
  medias?: Maybe<Array<Maybe<MediaType>>>;
};

export type ProductSpecialPriceArgs = {
  buyerId?: Maybe<Scalars['Int']>;
};

export type ProductSpecialUnitPriceArgs = {
  buyerId?: Maybe<Scalars['Int']>;
};

export type ProductBrand = {
  __typename?: 'ProductBrand';
  id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_vi?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  productCategories?: Maybe<Array<Maybe<ProductCategory>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductBrandsResponse = {
  __typename?: 'ProductBrandsResponse';
  data?: Maybe<Array<Maybe<ProductBrand>>>;
};

export type ProductCategoriesResponse = {
  __typename?: 'ProductCategoriesResponse';
  data?: Maybe<Array<ProductCategory>>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id: Scalars['Int'];
  slug: Scalars['String'];
  name: Scalars['String'];
  activated: Scalars['Boolean'];
  subCategories?: Maybe<Array<ProductCategory>>;
};

export type ProductFilterInput = {
  buyerId?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  stock?: Maybe<StockItem>;
  photo?: Maybe<PhotoFilter>;
  regionFiltersList?: Maybe<Array<Maybe<RegionFilter>>>;
  skuCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProductHistoryDetailInput = {
  uuid: Scalars['String'];
  pagination?: Maybe<Pagination>;
};

export type ProductHistoryDetailResponse = {
  __typename?: 'ProductHistoryDetailResponse';
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
  dataList?: Maybe<Array<Maybe<ActionHistoryInfo>>>;
};

export type ProductInfoByRegion = {
  __typename?: 'ProductInfoByRegion';
  itemId?: Maybe<Scalars['Int']>;
  regionCode?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  inStock?: Maybe<Scalars['Boolean']>;
  exclusive?: Maybe<Scalars['Boolean']>;
  limitedTimeOffer?: Maybe<LimitedTimeOffer>;
  updatedTime?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type ProductInfoByRegionInput = {
  itemId: Scalars['Int'];
  regionCode: Scalars['String'];
  price?: Maybe<IntValueByRegion>;
  inStock?: Maybe<BooleanValueByRegion>;
  exclusive?: Maybe<BooleanValueByRegion>;
  limitedTimeOffer?: Maybe<LimitedTimeOfferByRegionInput>;
};

export type ProductInput = {
  id?: Maybe<Scalars['String']>;
  internalCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameVn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionVn?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  detailsVn?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageFilePath?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  uom?: Maybe<Scalars['String']>;
  preservation?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  vatIncluded?: Maybe<Scalars['Boolean']>;
  private?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  productCategoryIds?: Maybe<Array<Scalars['ID']>>;
  brandId?: Maybe<Scalars['Int']>;
  productInfoByRegion?: Maybe<Array<Maybe<ProductInfoByRegionInput>>>;
  updatedBy?: Maybe<Scalars['String']>;
  skuPrefix?: Maybe<Scalars['String']>;
  medias?: Maybe<Array<Maybe<Media>>>;
};

export type ProductMutationResponse = MutationResponse & {
  __typename?: 'ProductMutationResponse';
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
  product?: Maybe<Product>;
};

export type ProductSort = {
  field?: Maybe<ProductSortField>;
  direction?: Maybe<SortDirection>;
};

export enum ProductSortField {
  Price = 'price',
  CreatedAt = 'createdAt',
  Preservation = 'preservation',
  Uom = 'uom',
}

export type ProductsResponse = {
  __typename?: 'ProductsResponse';
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
  data?: Maybe<Array<Maybe<Product>>>;
};

export enum ProductType {
  All = 'ALL',
  Exclusive = 'EXCLUSIVE',
}

export type QualityItem = {
  __typename?: 'QualityItem';
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
};

export type QualityItemInput = {
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<User>;
  supplier?: Maybe<Supplier>;
  user?: Maybe<User>;
  users?: Maybe<UsersResponse>;
  userGroups: Array<SupplierUserGroupWithPermissions>;
  product?: Maybe<Product>;
  productDiscountPriceBuyers?: Maybe<Array<Maybe<PriceBuyer>>>;
  products?: Maybe<ProductsResponse>;
  productsExport?: Maybe<Csv>;
  productCategories: ProductCategoriesResponse;
  order?: Maybe<Order>;
  orderExport?: Maybe<Csv>;
  orders?: Maybe<OrdersResponse>;
  ordersExport?: Maybe<Csv>;
  ordersPrint?: Maybe<Scalars['String']>;
  ordersPrintAll?: Maybe<Scalars['String']>;
  orderStatistic?: Maybe<OrderStatisticResponse>;
  orderActivity?: Maybe<OrderActivityResponse>;
  buyer?: Maybe<Buyer>;
  buyers?: Maybe<BuyersResponse>;
  stores?: Maybe<StoresV2Response>;
  paymentMethod: PaymentMethod;
  /** @deprecated Use productCategories instead */
  categories?: Maybe<Array<Maybe<Category>>>;
  tags: Array<Tag>;
  uoms?: Maybe<Array<Maybe<UomItem>>>;
  origins?: Maybe<Array<Maybe<Origin>>>;
  preservations?: Maybe<Array<Maybe<Preservation>>>;
  invitationSlot?: Maybe<InvitationSlotResponse>;
  settings?: Maybe<Settings>;
  buyerSettings?: Maybe<BuyerSettings>;
  invitations?: Maybe<InvitationsListResponse>;
  invitation?: Maybe<Invitation>;
  clientSellingList?: Maybe<ProductsResponse>;
  invitationSellingList?: Maybe<ProductsResponse>;
  brands?: Maybe<ProductBrandsResponse>;
  getAllKamereoRegion?: Maybe<GetAllKamereoRegionsResponse>;
  productHistoryDetail?: Maybe<ProductHistoryDetailResponse>;
  getItemsFulfillmentSpecs?: Maybe<GetItemsFulfillmentSpecsResponse>;
  printQRCodeItems?: Maybe<PrintQrCodeItemsResponse>;
  orderStatusHistoryDetail?: Maybe<OrderHistoryDetailResponse>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUsersArgs = {
  sort?: Maybe<Array<Maybe<UserSort>>>;
  filter?: Maybe<UserFilterInput>;
  pagination?: Maybe<Pagination>;
};

export type QueryProductArgs = {
  id: Scalars['ID'];
};

export type QueryProductDiscountPriceBuyersArgs = {
  id: Scalars['ID'];
  buyerId?: Maybe<Scalars['Int']>;
};

export type QueryProductsArgs = {
  sort?: Maybe<Array<Maybe<ProductSort>>>;
  filter?: Maybe<ProductFilterInput>;
  pagination?: Maybe<Pagination>;
};

export type QueryProductsExportArgs = {
  sort?: Maybe<Array<Maybe<ProductSort>>>;
  filter?: Maybe<ProductFilterInput>;
};

export type QueryOrderArgs = {
  id: Scalars['ID'];
};

export type QueryOrderExportArgs = {
  id: Scalars['ID'];
};

export type QueryOrdersArgs = {
  sort?: Maybe<Array<Maybe<OrderSort>>>;
  filter?: Maybe<OrderFilterInput>;
  pagination?: Maybe<Pagination>;
  regionCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryOrdersExportArgs = {
  sort?: Maybe<Array<Maybe<OrderSort>>>;
  filter?: Maybe<OrderFilterInput>;
  regionCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryOrdersPrintArgs = {
  orderPdfUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryOrdersPrintAllArgs = {
  input?: Maybe<OrderFilterInput>;
  regionCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryOrderStatisticArgs = {
  dateTime: DatetimeRange;
  regionCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryOrderActivityArgs = {
  dateTime: DatetimeRange;
  orderStatuses?: Maybe<Array<OrderStatus>>;
  regionCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryBuyerArgs = {
  id: Scalars['Int'];
};

export type QueryBuyersArgs = {
  sort?: Maybe<Array<Maybe<BuyerSort>>>;
  filter?: Maybe<BuyerFilterInput>;
  pagination?: Maybe<Pagination>;
};

export type QueryStoresArgs = {
  filter: StoreFilterInput;
  pagination: Pagination;
};

export type QueryPaymentMethodArgs = {
  buyerId: Scalars['Int'];
  supplierId?: Maybe<Scalars['ID']>;
};

export type QueryBuyerSettingsArgs = {
  buyerId: Scalars['Int'];
};

export type QueryInvitationsArgs = {
  filter?: Maybe<GetInvitationsInput>;
};

export type QueryInvitationArgs = {
  invitationId: Scalars['ID'];
};

export type QueryClientSellingListArgs = {
  input?: Maybe<ClientSellingListInput>;
};

export type QueryInvitationSellingListArgs = {
  invitationId: Scalars['ID'];
  filter?: Maybe<ProductFilterInput>;
  pagination?: Maybe<Pagination>;
};

export type QueryProductHistoryDetailArgs = {
  searchRequest?: Maybe<ProductHistoryDetailInput>;
};

export type QueryGetItemsFulfillmentSpecsArgs = {
  input?: Maybe<GetItemFulfillmentSpecsInput>;
};

export type QueryPrintQrCodeItemsArgs = {
  itemIds: Array<Maybe<Scalars['ID']>>;
};

export type QueryOrderStatusHistoryDetailArgs = {
  searchRequest?: Maybe<OrderHistoryDetailInput>;
};

export type RedInvoiceInfo = {
  __typename?: 'RedInvoiceInfo';
  companyName?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  taxCode?: Maybe<Scalars['String']>;
  emailToReceiveEInvoice?: Maybe<Scalars['String']>;
};

export type RegionFilter = {
  regionCode: Scalars['String'];
  inStock?: Maybe<BooleanValueByRegion>;
  exclusive?: Maybe<BooleanValueByRegion>;
};

export type RegionItem = {
  __typename?: 'RegionItem';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameVn?: Maybe<Scalars['String']>;
};

export enum ResourceGroup {
  Logo = 'LOGO',
  Avatar = 'AVATAR',
  ProductImage = 'PRODUCT_IMAGE',
  ProductVideo = 'PRODUCT_VIDEO',
}

export type SellingItem = {
  id: Scalars['ID'];
  price?: Maybe<Scalars['Int']>;
  vatIncluded?: Maybe<Scalars['Boolean']>;
  vat?: Maybe<Scalars['Int']>;
};

export type SellingItemForBuyersInput = {
  buyerId: Scalars['Int'];
  price?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Int']>;
  vatIncluded?: Maybe<Scalars['Boolean']>;
};

export enum SellingType {
  TypeAll = 'TYPE_ALL',
  TypePublic = 'TYPE_PUBLIC',
  TypeExclusive = 'TYPE_EXCLUSIVE',
}

export type Settings = {
  __typename?: 'Settings';
  id: Scalars['Int'];
  moq: Scalars['Int'];
  deliverySchedule: Array<DeliveryTimeSlotSetting>;
  deliveryDayOffs: Array<DeliveryDayOff>;
  fulfillmentTime: FulfillmentTime;
};

export type SettingsInput = {
  moq?: Maybe<Scalars['Int']>;
  deliverySchedule?: Maybe<Array<DeliveryTimeSlotSettingInput>>;
  fulfillmentTime?: Maybe<FulfillmentTimeInput>;
  deliveryDayOffs: Array<DeliveryDayOffInput>;
};

export type SettingsResponse = MutationResponse & {
  __typename?: 'SettingsResponse';
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
  settings?: Maybe<Settings>;
};

export type SignUploadResponse = MutationResponse & {
  __typename?: 'SignUploadResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  /** @deprecated Use signedUploadUrl */
  uploadUrl?: Maybe<Scalars['String']>;
  /** @deprecated Use imageFilePath */
  fullUrl?: Maybe<Scalars['String']>;
  signedUploadUrl?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  imageFilePath?: Maybe<Scalars['String']>;
};

export type SignUploadVideoResponse = MutationResponse & {
  __typename?: 'SignUploadVideoResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  signedUploadUrl?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum StockItem {
  All = 'ALL',
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

export type Store = {
  __typename?: 'Store';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  lastOrder?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  disabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<StoreStatus>;
  recordId?: Maybe<Scalars['Int']>;
};

export type StoreLastOrderArgs = {
  buyerId: Scalars['Int'];
};

export type StoreFilterInput = {
  internalId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  deliveryTime?: Maybe<DatetimeRange>;
  createdAt?: Maybe<DatetimeRange>;
  status?: Maybe<StoreStatus>;
  uuids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type StoresResponse = {
  __typename?: 'StoresResponse';
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
  data: Array<Store>;
};

export enum StoreStatus {
  Normal = 'NORMAL',
  Pending = 'PENDING',
  Blocked = 'BLOCKED',
  Rejected = 'REJECTED',
}

export type StoresV2Response = {
  __typename?: 'StoresV2Response';
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
  data: Array<StoreV2>;
};

export type StoreV2 = {
  __typename?: 'StoreV2';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  lastOrder?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  disabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<StoreStatus>;
  recordId?: Maybe<Scalars['Int']>;
  buyerId?: Maybe<Scalars['Int']>;
};

export type StoreV2LastOrderArgs = {
  buyerId: Scalars['Int'];
};

export type Supplier = {
  __typename?: 'Supplier';
  id: Scalars['Int'];
  name: Scalars['String'];
  status: CompanyStatus;
  paidStatus: PaidStatus;
  description?: Maybe<Scalars['String']>;
  descriptionVn?: Maybe<Scalars['String']>;
  motivationText?: Maybe<Array<Maybe<Scalars['String']>>>;
  motivationTextVn?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageUrl?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  defaultMOQ?: Maybe<Scalars['Int']>;
  sameDayLeadTime?: Maybe<Scalars['Int']>;
  leadTime?: Maybe<Scalars['Int']>;
  dateOffs?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultDeliveryTimeSlot?: Maybe<Array<Maybe<DeliveryTimeSlot>>>;
  telephone?: Maybe<Scalars['String']>;
};

export type SupplierUserGroup = {
  __typename?: 'SupplierUserGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdBy: Scalars['String'];
  activated: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
};

export type SupplierUserGroupWithPermissions = {
  __typename?: 'SupplierUserGroupWithPermissions';
  group?: Maybe<SupplierUserGroup>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
};

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String'];
  id: Scalars['String'];
};

export type TimeslotInput = {
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  dayOfWeek: Scalars['String'];
  on: Scalars['Boolean'];
};

export type UnitPrice = {
  __typename?: 'UnitPrice';
  value: Scalars['Int'];
  vat: Scalars['Int'];
  vatIncluded: Scalars['Boolean'];
};

export enum Uom {
  Kilogram = 'KILOGRAM',
  Liter = 'LITER',
  Pack = 'PACK',
  Can = 'CAN',
  Bottle = 'BOTTLE',
  Pieces = 'PIECES',
  Case = 'CASE',
  Box = 'BOX',
  Keg = 'KEG',
  PieceCon = 'PIECE_CON',
  PieceCai = 'PIECE_CAI',
  PieceLat = 'PIECE_LAT',
  PieceTrai = 'PIECE_TRAI',
  Block = 'BLOCK',
  Tray = 'TRAY',
  Jar = 'JAR',
  Crate = 'CRATE',
  Pail = 'PAIL',
  Sheet = 'SHEET',
  Cup = 'CUP',
  PackLoc = 'PACK_LOC',
  Bag = 'BAG',
  Roll = 'ROLL',
}

export type UomItem = {
  __typename?: 'UOMItem';
  nameLocal?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UpdateStoreInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  telephone: Scalars['String'];
  location: LocationInput;
  disabled: Scalars['Boolean'];
  imageUrl: Scalars['String'];
};

export type UpsertItemPriceForBuyersInput = {
  productId: Scalars['Int'];
  items?: Maybe<Array<SellingItemForBuyersInput>>;
};

export type UpsertItemsFulfillmentSpecsInput = {
  items?: Maybe<Array<Maybe<ItemsFulfillSpecInput>>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  telephone?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  lang?: Maybe<Language>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
};

export type UserError = {
  __typename?: 'UserError';
  field?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type UserFilterInput = {
  buyerId?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UserSort = {
  field?: Maybe<UserSortField>;
  direction?: Maybe<SortDirection>;
};

export enum UserSortField {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export type UsersResponse = {
  __typename?: 'UsersResponse';
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
  data?: Maybe<Array<Maybe<User>>>;
};

export enum UserStatus {
  Disabled = 'DISABLED',
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export type UserUpdateInput = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  name?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type ZeroOrderInfo = {
  __typename?: 'ZeroOrderInfo';
  reason?: Maybe<Scalars['String']>;
  receiver?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
};

export type ZeroOrderInput = {
  reason?: Maybe<Scalars['String']>;
  receiver?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
};
